.root {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000f18;
}

.auth-container {
  /* width: 50vw; */
  /* height: 200px; */
  padding: 5em;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: .5em;
}

.title {
  font-size: 2em;
  /* font-weight: bold; */
  margin-top: 0;
  /* font-family: Raleway; */
}