.templateButton {
  transform: scale(1);
  transition: 50ms;
  width: 12em;
  height: 12em;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin: 0 15px 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.templateButton:hover {
  transform: scale(1.01);
  transition: 50ms;
}