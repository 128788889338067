.App {
  text-align: center;
}


/* width */
 ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #25252510; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #0a171f50; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #0a171f70; 
}

.main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: center;
    /* height: 100vh; */
    /* background-color: #61dafb; */
    /* align-items: flex-start; */
}

.container {
    flex: 1;
    text-align: center;
    /* background-color: red; */
    /* height: 100%; */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 50px;
    /* background-color: #0a171f; */
    /* background-color: #61dafb; */
    
}

.pageContainer {
  margin: 25px 4em 0px 4em;
  padding-bottom: 50px;
  top: 0px
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.sidebar {
    height: 100%;
    width: 25%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #0a171f;
    transition: .5s ease;
    overflow-x: hidden;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.sidePadding {
    transition: .5s ease;
    overflow-x: hidden;
}

.sidebarButton {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background-color: #61dafb;
    position: fixed;
    z-index: 3;
    bottom: 25px;
    right: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: scale(1);
    transition: .05s;
}

.sidebarButton:hover {
    transform: scale(1.05);
    transition: .05s;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.col {
    display: flex;
    flex-direction: column;
}

.sideClickable {
    color: white;
    cursor: pointer;
    font-size: 18px;
    margin-top: 15px;
    text-align: left;
    width: 60px;
    margin-left: 20%;
    text-decoration: none;
}

.sideClickable:hover {
    transform: scale(1.02);
}

.routes-col {
    flex: 1;
    text-align: left;
}

.route-display {
  border: 1px;
  background-color: #0a171f;
}

.clickable {
  cursor: pointer;
  margin: 0 2em 0 0;
}

.App {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.nav {
  height: 3em;
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
  margin: 0 2em 0 0;
}

input, textarea {	
  width: 20em;
	height: 1.5em;
	margin: 5px
}

.homeLinks {
  display: flex;
  align-items: center;
}

.homeLink {
  margin: 5px;
}

.submit {
  width: 10em;
	height: 1.5em;
	margin: 15px 5px 0px 5px;
}

.listButton {
	width: 60px;
	margin: 5px;
}

.listItem {
	display: flex;
	width: 30em;
  margin-bottom: 10px;
  align-items: center;
}

li {
	flex: 1;
	text-align: left;
}

.container {
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.paddedRight {
  margin-right: 25px;
}

.row {
  display: flex;
  align-items: center;
}

.col {
  display: flex;
  flex-direction: column;
}

.displayContainer {
  border: 1px solid rgba(0, 0, 0, 0.15); 
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px
}

.settingsContainer {
  border: 1px solid rgba(0, 0, 0, 0.15); 
  border-radius: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-top: 25px;
  padding: 0 2em 0 2em;
  cursor: pointer;
  margin: 0 1em 0;
  height: 9em;
  transition: .25s;
}

.settingsContainer:hover {
  background-color: #0a171f0e;
  /* color: white; */
}