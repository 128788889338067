.app-sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 10em;
  max-width: 30em;
  display: flex;
  border-right: #e9e9e9 1px solid;
  flex-direction: row;
  /* background: #ffffff; */
  /* box-shadow: -8px 2px 22px -7px rgba(0, 0, 0, 0.25); */
  height: "100%";
  min-height: 70vh;
  /* flex: 1; */
  /* z-index: 0; */
  /* border-left: 1px solid rgba(0,0,0,0.15); */
}

.app-sidebar .app-sidebar-content {
  flex: 1;
  /* z-index:1 */
}

.app-sidebar .app-sidebar-resizer {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 10px;
  justify-self: flex-end;
  cursor: col-resize;
  resize: horizontal;
}

.app-sidebar .app-sidebar-resizer:hover {
  width: 4px;
  background: #c1c3c5b4;
}